import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import STYLES from '../styles/const';

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "privacy" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const { node } = data.allMarkdownRemark.edges[0];

  return (
    <Layout path="/privacy">
      <SEO
        title="個人情報保護方針"
        description="株式会社アールエムシー（以下「当社」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。"
      />
      <Wrapper>
        <Content dangerouslySetInnerHTML={{ __html: node.html }} />
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 40px 40px 0;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 1.3;
  h2 {
    margin-bottom: 8px;
    font-family: ${STYLES.FONT.FAMILY.MINCHO};
  }
  h4,
  h5 {
    font-size: 14px;
    font-weight: normal;
  }
  h4 {
    margin: 24px 0 18px;
  }
  h5 {
    margin: 40px 0 0;
  }
  ol {
    padding-left: 40px;
    list-style-type: decimal;
  }
  ul {
    padding-left: 60px;
    list-style-type: disc;
  }
`;

export default PrivacyPage;
